<template>
  <v-dialog
    v-model="isOpen"
    fullscreen
  >
    <v-card>
      <v-card-title
        class="full-width-card-title"
      >
      <v-icon flat @click="clicked">close</v-icon>System Info
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-container>
            <v-layout row v-for="service in this.getStackInfo" :key="service.name">
                <v-flex xs4>
                  <v-text-field label="Name" v-model="service.name" disabled />
                </v-flex>
                <v-flex xs4>
                  <v-text-field label="Version" v-model="service.version" disabled />
                </v-flex>
                <v-flex xs4 v-if="service.url">
                  <v-text-field label="Url" v-model="service.url" disabled />
                </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'ServerInfoDialog',
  props: {
    open: Boolean,
  },
  data: () => ({
    isOpen: false,
  }),
  watch: {
    open(val) {
      this.isOpen = val;
    },
  },
  computed: {
    ...mapGetters('systemInfo', [
      'getStackInfo',
    ]),
  },
  methods: {
    ...mapActions('systemInfo', [
      'fetchStackInfo',
    ]),
    clicked() {
      this.isOpen = false;
      this.$emit('clicked');
    },
  },
  async mounted() {
    await this.fetchStackInfo();
  },
};
</script>
